const campaignIds = [
  'valuefp112',
  'value',
  'Valuefp113',
  'Valuefb556',
  'tamil',
  'telgu',
  'Valuefb555',
  'valuefp557',
  'valuefp111',
];

export const signUpEvent = () => {
  console.log('track - registration');
  if (window?.fbq && campaignIds?.includes(localStorage.getItem('campaignId')))
    window.fbq('track', 'CompleteRegistration');
};

export const depositEvent = () => {
  console.log('track - purchase');
  if (window?.fbq && campaignIds?.includes(localStorage.getItem('campaignId')))
    window.fbq('track', 'Purchase');
};

export const pageViewEvent = () => {
  console.log('track - pageview');
  if (window?.fbq && campaignIds?.includes(localStorage.getItem('campaignId')))
    window.fbq('track', 'PageView');
};
